import React, { Suspense } from "react";
import Layout from "./Layout";
import Loader from "./components/loader/Loader";
import Footer from "./Footer";
const ApplicationRoutes = React.lazy(() => import("./config/appRoute"));

// Pendientes:
// - Validar que todos los campos estan llenos
// - Rellenar los datos al entrar
export default function App() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <div className="main">
          <Layout />
          <ApplicationRoutes />
          <Footer />
        </div>
      </Suspense>
    </>
  );
}
